<template>
  <div>
    <el-dialog
      v-model="showBalanceListDialog"
      width="1000"
      align-center
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      class="elv-account-balance-list-dialog"
      @close="onCloseDialog"
    >
      <template #header="{ titleId }">
        <h4 :id="titleId" class="elv-account-balance-list-dialog-header__title">
          {{ t('common.balance') }}
        </h4>
      </template>
      <div v-loading="loading" class="elv-account-balance-list-dialog-content">
        <ul
          v-if="entityStore.entityDetail?.progress?.entityFileCsvUploadList?.length"
          class="elv-account-multiple-balance-upload-status-list"
        >
          <li
            v-for="(item, index) in entityStore.entityDetail?.progress?.entityFileCsvUploadList"
            :key="index"
            class="elv-account-multiple-balance-upload-status-item"
          >
            <div class="elv-account-multiple-balance-upload-status-item__state">
              <template v-if="['FINISHED', 'FAILED', 'FAIL'].includes(item.status)">
                <template v-if="item.status === 'FINISHED'">
                  <SvgIcon class="success" name="checkmark-filled" width="18" height="18" fill="#94C300" />
                  {{ t('title.fileImportCompleted', { name: item?.entityFile?.name.split('.csv')?.[0] }) }}
                </template>
                <template v-else>
                  <SvgIcon class="success" name="sources-sync-error" width="18" height="18" fill="#94C300" />
                  {{ t('title.fileImportFailed', { name: item?.entityFile?.name.split('.csv')?.[0] }) }}
                </template>
              </template>
              <template v-else>
                <img src="@/assets/img/reports/sources-sync-loading.png" alt="loading" />
                {{ t('title.fileIsImporting', { name: item?.entityFile?.name.split('.csv')?.[0] }) }}
              </template>
            </div>
            <template v-if="['FINISHED', 'FAILED', 'FAIL'].includes(item.status)">
              <p class="elv-account-multiple-balance-upload-status-item__success">
                {{ t('common.successfully') }}: {{ formatNumber(item.successCount) }}
                {{ item.successCount > 1 ? t('common.items') : t('common.item') }}
              </p>
              <div v-if="item.failCount" class="elv-account-multiple-balance-upload-status-item__failure">
                {{ t('common.failure') }}:&nbsp;
                <p>{{ formatNumber(item.failCount) }}</p>
                &nbsp;{{ item.failCount > 1 ? t('common.items') : t('common.item') }}
                <span v-if="item.failFileDownloadUrl" @click="onDownloadFailFile(item.failFileDownloadUrl)">{{
                  t('button.clickDownloadAndReEdit')
                }}</span>
              </div>
            </template>
            <div
              v-if="['FINISHED', 'FAILED', 'FAIL'].includes(item.status)"
              v-loading="closeLoading && currentFileData.entityFileCsvUploadId === item.entityFileCsvUploadId"
              class="elv-account-multiple-balance-upload-status-item__close"
            >
              <SvgIcon name="common-close" width="18" height="18" fill="#838D95" @click="onCloseUploadStatus(item)" />
            </div>
          </li>
        </ul>
        <div class="elv-account-balance-list-dialog-content-header">
          <div class="elv-account-balance-screening-container">
            <div class="elv-account-balance-screening-item">
              <span class="elv-accounts-screening-item__label">{{ t('common.account') }}:</span>
              <el-select
                v-model="paramsData.entityAccountId"
                placeholder=" "
                :empty-values="[null, undefined]"
                :value-on-clear="undefined"
                popper-class="elv-accounts-screening-popper"
                @change="onChangeAccount"
              >
                <el-option
                  v-for="item in accountList"
                  :key="item?.entityAccount?.entityAccountId"
                  :label="item?.entityAccount?.name"
                  :value="item?.entityAccount?.entityAccountId"
                />
              </el-select>
            </div>
            <div v-if="currencyList?.length" class="elv-account-balance-screening-item">
              <span class="elv-accounts-screening-item__label">{{ t('common.currency') }}:</span>
              <el-select
                v-model="paramsData.currency"
                placeholder=" "
                :empty-values="[null, undefined]"
                :value-on-clear="undefined"
                popper-class="elv-accounts-screening-popper"
                @change="onChangeCurrency"
              >
                <el-option
                  v-for="item in currencyList"
                  :key="item?.symbol"
                  :label="item?.showSymbol"
                  :value="item?.symbol"
                >
                  <img
                    v-if="item?.showSymbol !== 'All'"
                    :src="item?.underlyingCurrency?.logo"
                    :alt="item?.showSymbol"
                  />{{ item?.showSymbol }}</el-option
                >
                <template #prefix>
                  <img v-if="currencyLogo" :src="currencyLogo" alt="currency" />
                </template>
              </el-select>
            </div>
            <div v-if="sourceList?.length" class="elv-account-balance-screening-item">
              <span class="elv-accounts-screening-item__label">{{ t('report.Source') }}:</span>
              <el-select
                v-model="paramsData.sourceId"
                placeholder=" "
                :empty-values="[null, undefined]"
                :value-on-clear="undefined"
                popper-class="elv-accounts-screening-popper"
                @change="onChangeCurrency"
              >
                <el-option v-for="item in sourceList" :key="item?.sourceId" :label="item?.name" :value="item?.sourceId">
                  {{ item?.name }}</el-option
                >
              </el-select>
            </div>
          </div>
          <div>
            <elv-button class="elv-account-balance-list-dialog-content-right__import" @click="onImportBalance">
              <SvgIcon name="Import-document" width="16" height="16" />{{ t('button.import') }}</elv-button
            >
            <elv-button
              v-if="
                currencyList?.length &&
                paramsData.entityAccountId !== 'ALL' &&
                paramsData.currency !== 'All' &&
                paramsData.currency !== ''
              "
              height="32"
              width="120"
              round
              plain
              type="primary"
              @click="onAddBalance"
            >
              <SvgIcon name="source-add" width="16" height="16" />{{ t('button.addBalance') }}</elv-button
            >
          </div>
        </div>
        <template v-if="currencyList?.length">
          <el-table
            ref="tableListRef"
            :data="checkpointData.list"
            border
            stripe
            max-height="500"
            class="elv-account-balance-table"
            header-cell-class-name="elv-account-balance-table-header__cell"
            header-row-class-name="elv-account-balance-table-header"
            row-class-name="elv-account-balance-table-row"
            cell-class-name="elv-account-balance-table-row__cell"
          >
            <el-table-column type="index" width="34" align="center" class-name="elv-account-balance-table-row__index" />
            <el-table-column width="170" :label="t('common.dateTime')">
              <template #default="{ row }">
                <el-tooltip
                  :show-arrow="false"
                  effect="dark"
                  placement="top"
                  popper-class="elv-report-table-tips"
                  overlay-classname="elv-report-table-tips"
                  :show-after="500"
                >
                  <div class="elv-account-balance-table-row__datetime">
                    {{
                      dayjs(row?.datetime)
                        .tz(entityStore.entityDetail?.timezone ?? 'UTC')
                        .format('YYYY/MM/DD HH:mm:ss')
                    }}
                  </div>
                  <template #content>
                    <div>
                      <p>
                        {{ t('project.originalDatetime')
                        }}{{ calculateUtcOffset(row?.datetime, row?.timezone ?? 'UTC') }}:
                        {{
                          dayjs(row?.datetime)
                            .tz(row?.timezone ?? 'UTC')
                            .format('YYYY/MM/DD HH:mm:ss')
                        }}
                      </p>
                      <p>
                        {{ t('project.utcDatetime') }}:
                        {{ dayjs.tz(row?.datetime, 'UTC').format('YYYY/MM/DD HH:mm:ss') }}
                      </p>
                      <p>
                        {{ t('project.reportingDatetime')
                        }}{{ calculateUtcOffset(row?.datetime, entityStore.entityDetail?.timezone ?? 'UTC') }}:
                        {{
                          dayjs(row?.datetime)
                            .tz(entityStore.entityDetail?.timezone ?? 'UTC')
                            .format('YYYY/MM/DD HH:mm:ss')
                        }}
                      </p>
                    </div>
                  </template>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column width="130" :label="t('common.account')">
              <template #default="{ row }">
                <IconWithTextCell
                  :icon="row?.entityAccount?.platform?.logo ?? ''"
                  :title="row.entityAccount?.name ?? ''"
                  :titleWeight="400"
                  title-size="12px"
              /></template>
            </el-table-column>
            <el-table-column width="130" :label="t('report.Source')" class-name="elv-account-balance-table-row__source">
              <template #default="{ row }">
                <IconWithTextCell
                  v-if="row?.source?.type === 'CSV_BALANCE'"
                  svgName="csv-file"
                  class="elv-account-balance-table-row__source_csv"
                  :title="row.source?.name ?? ''"
                  :svgSize="18"
                  :titleWeight="400"
                />
                <template v-else-if="row?.creator === 'OPENAPI'"
                  ><SvgIcon name="API" width="18" height="18" fill="#AAAFB6" style="margin-right: 8px" />OpenAPI
                </template>
                <template v-else-if="row.creator === 'USER'"
                  ><SvgIcon name="add-balance" width="18" height="18" fill="#AAAFB6" style="margin-right: 8px" />Manual
                </template>
                <IconWithTextCell
                  v-else
                  :icon="row?.provider?.logo ?? ''"
                  :title="row.provider?.name ?? ''"
                  :titleWeight="400"
                />
              </template>
            </el-table-column>
            <el-table-column width="90" :label="t('common.currency')">
              <template #default="{ row }">
                <IconWithTextCell
                  :icon="row?.underlyingCurrency?.logo ?? ''"
                  :title="row.underlyingCurrency?.showSymbol ?? ''"
                  :titleWeight="400"
              /></template>
            </el-table-column>
            <el-table-column
              width="180"
              :label="t('common.balance')"
              align="right"
              header-align="right"
              class-name="elv-account-balance-table-row__balance"
            >
              <template #default="{ row }">{{ formatNumber(row.balance, 20) }}</template>
            </el-table-column>
            <el-table-column
              width="130"
              :label="t('report.addedBy')"
              class-name="elv-account-balance-table-row__avatar"
            >
              <template #default="{ row }">
                <template v-if="row?.user?.email">
                  <VueBlockies
                    v-if="row?.user?.name"
                    style="border-radius: 50%"
                    :seed="md5UserParams(row?.user?.userId)"
                    :color="changeAvatarColor(md5UserParams(row?.user?.userId))[0]"
                    :bgcolor="changeAvatarColor(md5UserParams(row?.user?.userId))[1]"
                    :size="7"
                    :scale="4"
                    spot-color="#666"
                  />
                  <img v-else src="@/assets/img/member-invite-avatar.png" alt="avatar" />
                  <p>{{ row?.user?.name }}</p>
                </template>
                <p v-else>-</p>
              </template>
            </el-table-column>
            <el-table-column width="140" :label="t('report.addedDate')">
              <template #default="{ row }">
                <el-tooltip
                  :show-arrow="false"
                  effect="dark"
                  placement="top"
                  popper-class="elv-report-table-tips"
                  overlay-classname="elv-report-table-tips"
                  :show-after="500"
                >
                  <div class="elv-account-balance-table-row__datetime">
                    {{
                      dayjs(row?.createdAt)
                        .tz(entityStore.entityDetail?.timezone ?? 'UTC')
                        .format('YYYY/MM/DD HH:mm')
                    }}
                  </div>
                  <template #content>
                    <div>
                      <p>
                        {{ t('project.originalDatetime')
                        }}{{ calculateUtcOffset(row?.createdAt, row?.timezone ?? 'UTC') }}:
                        {{
                          dayjs(row?.createdAt)
                            .tz(row?.timezone ?? 'UTC')
                            .format('YYYY/MM/DD HH:mm')
                        }}
                      </p>
                      <p>
                        {{ t('project.utcDatetime') }}:
                        {{ dayjs.tz(row?.createdAt, 'UTC').format('YYYY/MM/DD HH:mm') }}
                      </p>
                      <p>
                        {{ t('project.reportingDatetime')
                        }}{{ calculateUtcOffset(row?.createdAt, entityStore.entityDetail?.timezone ?? 'UTC') }}:
                        {{
                          dayjs(row?.createdAt)
                            .tz(entityStore.entityDetail?.timezone ?? 'UTC')
                            .format('YYYY/MM/DD HH:mm')
                        }}
                      </p>
                    </div>
                  </template>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column width="137" :label="t('report.addedMethod')">
              <template #default="{ row }">
                {{
                  row.creator === 'USER'
                    ? t('report.manualAdded')
                    : row?.creator === 'OPENAPI'
                      ? 'OpenAPI'
                      : t('report.automaticSynced')
                }}
              </template>
            </el-table-column>
            <el-table-column width="50" label="" align="center" class-name="elv-account-balance-table-row__index">
              <template #default="{ row }">
                <SvgIcon
                  v-if="(row.creator === 'USER' && row?.source?.type !== 'CSV_BALANCE') || row?.creator === 'OPENAPI'"
                  name="sources-delete"
                  width="18"
                  height="18"
                  fill="#838D95"
                  style="cursor: pointer"
                  @click="onOpenDeleteConfirm(row.treasuryBalanceCheckpointId)"
                />
                <span v-else>-</span>
              </template>
            </el-table-column>
          </el-table>
          <elv-pagination
            v-if="checkpointData.total > 0"
            size-popper-class="elv-account-balance-pagination__sizes-popper"
            :limit="paramsData.limit"
            :current-page="paramsData.page"
            :total="checkpointData.total"
            @current-change="onChangePage"
            @size-change="onChangePageSize"
          />
        </template>
        <div v-if="!currencyList?.length" class="elv-account-balance-list-dialog-content-empty">
          <SvgIcon name="nominal" width="24" height="24" /> {{ t('message.accountBalanceEmptyInfo') }}
        </div>
      </div>
    </el-dialog>
    <AddBalanceDialog ref="addBalanceDialogRef" :current-data="currentAccountData" @resetDetail="resetList" />
    <ElvMessageBox
      ref="deleteMessageBoxRef"
      :showCancelButton="true"
      :confirmButtonText="t('button.delete')"
      :cancelButtonText="t('button.cancel')"
      :title="t('title.deleteBalance')"
      :loading="deleteLoading"
      @onConfirmEvent="onConfirmExecutingEvent"
      @onCancelEvent="onCancelExecutingEvent"
    >
      <template #content>
        <span class="elv-confirm-info-header__title balance">{{ t('message.deleteBalanceInfo') }}</span></template
      >
    </ElvMessageBox>
    <UploadCSVDialog
      ref="uploadCSVDialogRef"
      :title="t('title.importMultipleBalance')"
      templateSlug="bulk-balance"
      templateUrl="file/csv/Elven%20Batch%20Import%20Balance%20Template.csv"
      :noPermission="
        ['MEMBER', ''].includes(currentEntityPermission?.role) && !currentEntityPermission?.entityAccount?.create
      "
      :submitText="t('button.import')"
      @onSaveImport="onSaveImport"
      @onResetList="resetUploadFile"
    >
      <template #importExtendContent>
        <div class="elv-account-balance-list-dialog-content-import-tip-info">
          <p>{{ t('message.importBalanceMultipleTipInfo') }}</p>
          <i18n-t keypath="message.importBalanceMultipleNameInfo" tag="p" scope="global">
            <template #format>
              <span>{{ t('message.importBalanceMultipleNameFormat') }}</span>
            </template>
          </i18n-t>
        </div>
      </template>
    </UploadCSVDialog>
    <LimitReachedDialog ref="limitReachedDialogRef" @onConfirmUpgradeProjectPlan="onConfirmUpgradeProjectPlan" />
    <UpgradeProjectPlanDialog ref="upgradeProjectPlanDialogRef" />
  </div>
</template>

<script setup lang="ts">
import dayjs from 'dayjs'
import md5 from 'js-md5'
import { useI18n } from 'vue-i18n'
import utc from 'dayjs/plugin/utc'
import VueBlockies from 'vue-blockies'
import { ElMessage } from 'element-plus'
import { avatarColor } from '@/config/index'
import AccountsApi from '@/api/AccountsApi'
import timezone from 'dayjs/plugin/timezone'
import { downloadFile } from '@/lib/download'
import AddBalanceDialog from './AddBalanceDialog.vue'
import { ProjectSubscribeStatus } from '#/ProjectTypes'
import { useEntityStore } from '@/stores/modules/entity'
import { useGlobalStore } from '@/stores/modules/global'
import { useProjectStore } from '@/stores/modules/project'
import { find, flatMap, cloneDeep, isEmpty } from 'lodash-es'
import { useAccountStore } from '@/stores/modules/accounts'
import { formatNumber, calculateUtcOffset } from '@/lib/utils'
import IconWithTextCell from '@/components/Base/Table/IconWithTitleCell.vue'
import UploadCSVDialog from '@/pages/Financials/Project/components/UploadCSVDialog.vue'
import LimitReachedDialog from '@/pages/Financials/components/LimitReachedDialog.vue'
import UpgradeProjectPlanDialog from '@/pages/Financials/ProjectSetting/components/UpgradeProjectPlanDialog.vue'

const props = defineProps({
  subGroup: {
    type: String,
    default: 'NONE'
  }
})

dayjs.extend(utc)
dayjs.extend(timezone)
const { t } = useI18n()
const route = useRoute()
const loading = ref(false)
const globalStore = useGlobalStore()
const accountStore = useAccountStore()
const projectStore = useProjectStore()
const paramsData = ref({
  entityAccountId: '',
  currency: '',
  sourceId: '',
  page: 1,
  limit: 20
})

const deleteLoading = ref(false)
const showBalanceListDialog = ref(false)
const currentAccountData: any = ref({})
const entityStore = useEntityStore()
const currentFileData = ref<any>({})
const closeLoading = ref(false)
const addBalanceDialogRef = useTemplateRef('addBalanceDialogRef')
const deleteMessageBoxRef = useTemplateRef('deleteMessageBoxRef')
const uploadCSVDialogRef = useTemplateRef('uploadCSVDialogRef')
const limitReachedDialogRef = useTemplateRef('limitReachedDialogRef')
const upgradeProjectPlanDialogRef = useTemplateRef('upgradeProjectPlanDialogRef')

const treasuryBalanceAccountList = computed(() => {
  const firstList = flatMap(accountStore.treasuryBalanceList.list, 'list') ?? []
  if (props.subGroup === 'NONE') {
    return firstList
  }
  return flatMap(firstList, 'list') ?? []
})

const accountList = computed(() => {
  const list = [
    {
      entityAccount: {
        entityAccountId: '',
        name: t('common.all')
      }
    }
  ]
  treasuryBalanceAccountList.value.forEach((item: any) => {
    list.push(item)
  })
  return list
})
const accountDetail: any = ref({})
const treasuryBalanceCheckpointId = ref('')
const currencyList: any = ref([
  {
    symbol: '',
    showSymbol: 'All'
  }
])
const sourceList: any = ref([
  {
    sourceId: '',
    name: t('common.all')
  },
  {
    sourceId: '0',
    name: 'Manual',
    creator: 'USER'
  },
  {
    sourceId: 'OPENAPI',
    name: 'OpenAPI',
    creator: 'OPENAPI'
  }
])
const checkpointData: any = ref({
  list: [],
  total: 0
})

const entityId = computed(() => {
  return route.params?.entityId as string
})

const md5UserParams = computed(() => {
  return (userId: string) => {
    const md5UserId = userId.slice(0, 18)
    return md5(md5UserId)
  }
})

const currentEntityPermission = computed(() => {
  return entityStore.entityPermission()
})

const currencyLogo = computed(() => {
  return find(currencyList.value, { currency: paramsData.value.currency })?.underlyingCurrency?.logo
})

const projectDetail = computed(() => {
  if (!isEmpty(projectStore.projectDetail)) return projectStore.projectDetail
  return globalStore.projectDetail(entityStore.entityDetail?.projectId)
})

const onConfirmUpgradeProjectPlan = () => {
  upgradeProjectPlanDialogRef.value?.changeUpgradeProjectDialogStatus()
}

const onCheckBalanceListDialog = () => {
  showBalanceListDialog.value = !showBalanceListDialog.value
}

const changeAvatarColor: any = (val: string) => {
  const lastStr = val.substr(val.length - 1, 1)
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(Number(lastStr))) {
    return changeAvatarColor(val.substr(0, val.length - 1))
  }
  return avatarColor[lastStr]
}

const onAddBalance = () => {
  if (
    ['MEMBER', ''].includes(currentEntityPermission.value?.role) &&
    !currentEntityPermission.value?.entityAccount?.balanceAdd
  ) {
    ElMessage.warning(t('message.noPermission'))
    return
  }
  currentAccountData.value = {
    entityAccountId: paramsData.value.entityAccountId,
    currency: paramsData.value.currency,
    name: accountDetail.value.name,
    underlyingCurrency: { logo: currencyLogo.value }
  }
  addBalanceDialogRef.value?.onCheckAccountBalanceDialog()
}

const fetchEntityAccountDetail = async () => {
  try {
    if (!paramsData.value.entityAccountId) return
    loading.value = true
    const { data } = await AccountsApi.getEntityAccountDetail(entityId.value, paramsData.value.entityAccountId)
    accountDetail.value = data
  } catch (error) {
    console.log(error)
  } finally {
    loading.value = false
  }
}

const fetchTreasuryBalanceCheckpointList = async () => {
  try {
    loading.value = true
    const params: any = cloneDeep(paramsData.value)
    if (!paramsData.value.currency) {
      delete params.currency
    }
    if (!paramsData.value.entityAccountId) {
      delete params.entityAccountId
    }
    if (!paramsData.value.sourceId) {
      delete params.sourceId
    } else {
      switch (paramsData.value.sourceId) {
        case '0':
          params.creator = 'USER'
          break
        case 'OPENAPI':
          params.creator = 'OPENAPI'
          params.sourceId = '0'
          break
        default:
          params.creator = 'SOURCE'
          break
      }
    }
    const { data } = await AccountsApi.getTreasuryBalanceCheckpointList(entityId.value, params)
    checkpointData.value = data
  } catch (error) {
    console.log(error)
  } finally {
    loading.value = false
  }
}

const onChangeAccount = async (val: string) => {
  paramsData.value.entityAccountId = val
  checkpointData.value = {
    list: [],
    total: 0
  }
  paramsData.value.page = 1
  await fetchEntityAccountDetail()
  fetchTreasuryBalanceCheckpointList()
}

const onChangeCurrency = () => {
  checkpointData.value = {
    list: [],
    total: 0
  }
  paramsData.value.page = 1
  fetchTreasuryBalanceCheckpointList()
}

const onChangePage = (page: number) => {
  paramsData.value.page = page
  fetchTreasuryBalanceCheckpointList()
}

const onChangePageSize = (limit: number) => {
  paramsData.value.limit = limit
  paramsData.value.page = 1
  fetchTreasuryBalanceCheckpointList()
}

const resetList = () => {
  paramsData.value.page = 1
  fetchTreasuryBalanceCheckpointList()
}

const onDeleteBalance = async () => {
  if (
    ['MEMBER', ''].includes(currentEntityPermission.value?.role) &&
    !currentEntityPermission.value?.entityAccount?.balanceDelete
  ) {
    ElMessage.warning(t('message.noPermission'))
    return
  }
  try {
    deleteLoading.value = true
    await AccountsApi.deleteTreasuryBalanceCheckpoint(entityId.value, treasuryBalanceCheckpointId.value)
    deleteMessageBoxRef.value?.onCheckMessageBoxDialog()
    ElMessage.success(t('message.deleteSuccess'))
    resetList()
  } catch (error: any) {
    console.log(error)
    ElMessage.error(error.message)
  } finally {
    deleteLoading.value = false
  }
}

const onOpenDeleteConfirm = (id: string) => {
  if (
    ['MEMBER', ''].includes(currentEntityPermission.value?.role) &&
    !currentEntityPermission.value?.entityAccount?.balanceDelete
  ) {
    ElMessage.warning(t('message.noPermission'))
    return
  }
  treasuryBalanceCheckpointId.value = id
  deleteMessageBoxRef.value?.onCheckMessageBoxDialog()
}

const onCancelExecutingEvent = () => {
  deleteMessageBoxRef.value?.onCheckMessageBoxDialog()
}

const onConfirmExecutingEvent = () => {
  onDeleteBalance()
}
const onCloseDialog = () => {
  paramsData.value.entityAccountId = ''
  paramsData.value.currency = ''
}

const onImportBalance = () => {
  if (
    ['MEMBER', ''].includes(currentEntityPermission.value?.role) &&
    !currentEntityPermission.value?.entityAccount?.create
  ) {
    ElMessage.warning(t('message.noPermission'))
    return
  }
  if (
    projectDetail.value?.subscribeStatus === ProjectSubscribeStatus.EXCEEDED ||
    projectDetail.value?.txCountStatus === ProjectSubscribeStatus.EXCEEDED
  ) {
    limitReachedDialogRef.value?.changeLimitReachedDialogStatus()
    return
  }
  uploadCSVDialogRef.value?.onCheckUploadCSVDialog()
}

const onSaveImport = async (params: any) => {
  try {
    const data = {
      entityFileId: params.entityFileId
    }
    const res = await AccountsApi.importMultipleBalanceForAccount(entityId.value, data)
    uploadCSVDialogRef.value?.onSuccessImport()
    resetList()
    return Promise.resolve(res)
  } catch (error: any) {
    ElMessage.error(error.message)
    return Promise.reject(error)
  } finally {
    uploadCSVDialogRef.value?.onFinallyImport()
  }
}

const resetUploadFile = () => {
  console.log('resetMultipleBalanceList')
}

const onDownloadFailFile = (failFileDownloadUrl: string) => {
  downloadFile(failFileDownloadUrl)
}

const onCloseUploadStatus = async (row: any) => {
  try {
    currentFileData.value = row
    closeLoading.value = true
    await AccountsApi.closeBalanceAccountCsvUploadStatus(entityId.value, row.entityFileCsvUploadId)
    ElMessage.success(t('message.success'))
    entityStore.fetchEntityDetail(entityId.value)
  } catch (error: any) {
    console.log(error)
    ElMessage.error(error.message)
  } finally {
    closeLoading.value = false
  }
}

defineExpose({ onCheckBalanceListDialog, showBalanceListDialog })

watch(
  [() => showBalanceListDialog.value, () => accountList.value],
  async () => {
    if (showBalanceListDialog.value && !isEmpty(accountList.value)) {
      paramsData.value.page = 1
      const treasuryBalanceCurrencyList = accountStore.treasuryBalanceList.total.currencyList.map((item: any) => {
        return {
          symbol: item.underlyingCurrency?.symbol,
          showSymbol: item.underlyingCurrency?.showSymbol,
          ...item
        }
      })
      currencyList.value = [
        {
          symbol: '',
          showSymbol: 'All'
        },
        ...treasuryBalanceCurrencyList
      ]
      await fetchEntityAccountDetail()
      await fetchTreasuryBalanceCheckpointList()
      const data = await accountStore.fetchAccountSourceList(entityId.value, {
        page: 1,
        limit: 100,
        withBalance: true
      })
      const sourceData = data.list.map((item: any) => {
        return {
          ...item,
          creator: 'SOURCE'
        }
      })
      sourceList.value = [
        {
          sourceId: '',
          name: t('common.all'),
          creator: ''
        },
        {
          sourceId: '0',
          name: 'Manual',
          creator: 'USER'
        },
        {
          sourceId: 'OPENAPI',
          name: 'OpenAPI',
          creator: 'OPENAPI'
        },
        ...sourceData
      ]
    }
  },
  { immediate: true }
)
</script>

<style lang="scss">
.elv-account-balance-list-dialog {
  width: 1000px;
  min-height: 271px;
  box-shadow:
    0px 2px 6px rgba(0, 0, 0, 0.05),
    0px 0px 1px rgba(0, 0, 0, 0.3);
  border-radius: 6px;

  .el-dialog__header {
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    height: 54px;
    font-family: 'Plus Jakarta Sans';
    font-weight: 700;
    margin: 0;
    padding: 0;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: flex-start;
    color: #0e0f11;
    padding-left: 24px;
    border-bottom: 1px solid #edf0f3;

    .elv-account-balance-list-dialog-header__title {
      font-family: 'Plus Jakarta Sans';
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      color: #0e0f11;
      margin: 0;
    }
  }

  .el-dialog__body {
    padding: 18px 24px 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }

  .elv-account-balance-list-dialog-content {
    width: 100%;

    .elv-account-multiple-balance-upload-status-list {
      width: 100%;
      box-sizing: border-box;
      display: inline-flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 12px;
      margin-bottom: 16px;

      .elv-account-multiple-balance-upload-status-item {
        width: 100%;
        height: 38px;
        display: flex;
        box-sizing: border-box;
        padding: 10px 8px;
        align-items: center;
        border-radius: 2px;
        border: 1px solid #abc0f5;
        background: rgba(229, 237, 255, 0.2);
        gap: 32px;
        position: relative;

        .elv-account-multiple-balance-upload-status-item__state {
          display: flex;
          align-items: center;
          gap: 8px;
          color: #1e2024;
          font-family: 'Plus Jakarta Sans';
          font-size: 13px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;

          img {
            width: 18px;
            height: 18px;
            display: block;
            animation: loading-rotate 2s linear infinite;
          }
        }

        .elv-account-multiple-balance-upload-status-item__success {
          color: #1e2024;
          font-family: 'Plus Jakarta Sans';
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }

        .elv-account-multiple-balance-upload-status-item__failure {
          display: flex;
          align-items: center;
          color: #1e2024;
          font-family: 'Plus Jakarta Sans';
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;

          p {
            color: #df2200;
          }

          span {
            color: #1753eb;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-decoration-line: underline;
            text-transform: lowercase;
            margin-left: 8px;
            cursor: pointer;
          }
        }

        .elv-account-multiple-balance-upload-status-item__close {
          position: absolute;
          right: 8px;
          cursor: pointer;

          &:hover svg {
            fill: #1343bf;
          }

          .el-loading-mask {
            .circular {
              width: 28px;
              height: 28px;
              position: relative;
              top: 6px;
              left: -6px;
            }
          }
        }
      }
    }
  }

  .elv-account-balance-list-dialog-content-header {
    margin-bottom: 16px;
    height: 32px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;

    .elv-account-balance-screening-container {
      display: flex;
      align-items: center;

      .elv-account-balance-screening-item {
        margin-right: 16px;
        display: flex;
        align-items: center;
      }
    }

    .el-select {
      height: 15px;
      width: fit-content;

      .el-select__wrapper {
        height: 15px;
        min-height: 15px;
        border: none !important;
        box-shadow: none !important;
        padding: 0;
        padding-left: 8px;

        .el-select__selection {
          width: fit-content;

          .el-select__placeholder {
            position: relative;
            top: 0;
            transform: none;
            color: #1e2024;
            font-family: 'Plus Jakarta Sans';
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            width: fit-content;
            overflow: visible;
            min-width: 10px;
          }
        }

        .el-select__prefix {
          img {
            width: 16px;
            height: 16px;
            display: block;
            border-radius: 50%;
          }
        }

        .el-select__suffix {
          .el-icon {
            margin-left: 4px;

            svg {
              width: 12px;
              height: 12px;
            }
          }
        }
      }
    }
  }

  .elv-account-balance-table {
    --el-table-border-color: #d4dce5;
    --el-table-border: 1px solid #edf0f3;
    --el-fill-color-lighter: #f9fafb;
    width: 100%;
    box-sizing: border-box;
    border: 1px solid #d4dce6;
    border-right: 0px;
    border-left: 0px;
    border-bottom-width: 1px;

    .el-table__inner-wrapper {
      width: 100%;

      &::after,
      &::before {
        width: 0;
        height: 0;
      }
    }

    &.el-table--border {
      border-bottom-color: #edf0f3;
    }

    .el-table__body-wrapper {
      height: 616px;

      .el-scrollbar__view {
        width: 100%;
        margin-bottom: 0px !important;
      }
    }

    .el-table__border-left-patch {
      width: 0;
    }

    .elv-account-balance-table-header {
      background: #eef4fb;

      .elv-account-balance-table-header__cell {
        background: #eef4fb;
        height: 36px;
        box-sizing: border-box;
        border-right-color: #e4e7eb;
        border-bottom-color: #ced7e0;
        padding: 0px;

        &:last-child {
          border-right: 0px;
        }

        &.is-right .cell {
          justify-content: flex-end;
        }

        .cell {
          font-family: 'Plus Jakarta Sans';
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 15px;
          color: #666d74;
          padding: 0 10px;
          display: flex;
          align-items: center;
          justify-items: center;
        }
      }
    }

    .elv-account-balance-table-row__cell {
      padding: 0;
      height: 42px;

      &:last-child {
        border-right: 0px;
      }

      .cell {
        padding: 0 10px;
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        color: #0e0f11;
        line-height: 16px;
        display: flex;
        align-items: center;
        // justify-content: center;
        .elv-base-cell-icon-title {
          padding: 0px;
        }
      }

      .elv-account-balance-table-row__datetime {
        color: #0e0f11;
        font-family: 'Barlow';
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      &.elv-account-balance-table-row__source .cell {
        .elv-account-balance-table-row__source_csv {
          svg {
            border-radius: 0px;
          }
        }
      }

      &.elv-account-balance-table-row__index .cell {
        color: #aaafb6;
        font-family: 'Barlow';
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      &.elv-account-balance-table-row__balance .cell {
        font-family: 'Barlow';
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        white-space: nowrap;
      }

      &.elv-account-balance-table-row__avatar .cell {
        display: flex;
        font-family: 'Plus Jakarta Sans';
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        img {
          width: 18px;
          height: 18px;
          display: block;
          margin-right: 4px;
          border-radius: 50%;
        }
      }

      &.is-right .cell {
        text-align: right;
        justify-content: flex-end;
      }

      &.is-center .cell {
        text-align: center;
        justify-content: center;
      }
    }

    .el-table__footer-wrapper {
      .el-table__footer {
        height: 42px;

        .cell.elv-account-balance-table-row__total {
          color: #636b75;
          font-family: 'Plus Jakarta Sans';
          font-size: 11px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }

        .cell.elv-account-balance-table-row__amount {
          color: #0e0f11;
          font-family: 'Barlow';
          font-size: 13px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          text-transform: uppercase;

          p {
            color: #838d95;
            font-family: 'Barlow';
            font-size: 11px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-transform: none;
          }
        }
      }
    }
  }

  .elv-account-balance-pagination__sizes-popper.el-popper {
    transform: translateY(10.5px);
  }

  .elv-account-balance-list-dialog-content-empty {
    display: flex;
    height: 127px;
    width: 100%;
    box-sizing: border-box;
    padding: 40px 0px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    color: #aaafb6;
    font-family: 'Plus Jakarta Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    svg {
      margin-bottom: 8px;
    }
  }

  .el-dialog__footer {
    padding: 20px 0px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.elv-confirm-info-header__title.balance {
  color: #636b75;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.elv-account-balance-list-dialog-content-right__import {
  border: none;
  box-shadow: none;

  span {
    color: #636b75;
    font-size: 13px;
  }

  svg {
    fill: #838d95;
    margin-right: 6px;
  }

  &:focus {
    border: none !important;
    box-shadow: none !important;
  }

  &:hover {
    border: none !important;
    box-shadow: none !important;

    span {
      color: #1343bf;
    }

    svg {
      fill: #1343bf;
    }
  }
}

.elv-account-balance-list-dialog-content-import-tip-info {
  margin-top: 10px;
  color: #838d95;
  font-family: 'Plus Jakarta Sans';
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;

  p {
    &::before {
      content: '•';
      color: #838d95;
      margin-right: 4px;
    }

    &:first-child {
      text-indent: -10px;
      padding-left: 10px;
    }

    span {
      color: #1e2024;
    }
  }
}
</style>
