<template>
  <div class="elv-account-table">
    <ag-grid-vue
      v-if="processTableConfig.length > 0"
      :style="{
        height: props.height,
        minWidth: '406px',
        maxWidth: '100%'
      }"
      class="elv-ag-theme-alpine"
      :grid-options="gridOptions"
      :enable-cell-change-flash="false"
      :column-defs="processTableConfig"
      :loading-cell-renderer="TableLoadingCell"
      :loading-cell-renderer-params="loadingCellRendererParams"
      :pinned-top-row-data="pinnedTopRowData"
      :is-server-side-group-open-by-default="isServerSideGroupOpenByDefault"
      :get-row-id="getRowId"
      @cellClicked="onCellClicked"
      @grid-ready="onGridReady"
    />

    <AccountDetailDrawer
      ref="accountOverlayDrawerRef"
      :drawerData="overlayDrawerData.currentData"
      :tableType="overlayDrawerData.type"
      @resetList="resetList"
      @onUpdateAccountOrContact="onUpdateAccountOrContact"
    />

    <CounterPartyDetailDrawer
      ref="counterPartyOverlayDrawerRef"
      :drawerData="overlayDrawerData.currentData"
      :tableType="overlayDrawerData.type"
      @resetList="resetList"
      @onUpdateAccountOrContact="onUpdateAccountOrContact"
    />
  </div>
</template>
<script setup lang="ts">
import { emitter } from '@/lib/mitt'
import { AgGridVue } from 'ag-grid-vue3'
import { LicenseManager } from 'ag-grid-enterprise'
import 'ag-grid-community/styles/ag-grid.css' // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine.css' // Optional theme CSS
import { type GridApi, ColumnApi, DetailGridInfo, IServerSideGetRowsParams } from 'ag-grid-enterprise'

import { useI18n } from 'vue-i18n'
import { ElMessage } from 'element-plus'
import noDataImg from '@/assets/img/noData.png'
import { useEntityStore } from '@/stores/modules/entity'
import { isEmpty, uniqueId, capitalize } from 'lodash-es'
import AccountDetailDrawer from './AccountDetailDrawer.vue'
import { useAccountStore } from '@/stores/modules/accounts'
import CustomTooltip from '@/components/Reports/Cell/Tooltips.vue'
import TableLoadingCell from '@/components/Reports/Cell/TableLoadingCell.vue'
import { useReportsTableConfigStore } from '@/stores/modules/reports/tableConfig'
import CounterPartyDetailDrawer from '../../../components/CounterPartyDetailDrawer.vue'

LicenseManager.setLicenseKey(import.meta.env.VITE_TABLE_KEY)

const props = defineProps({
  type: {
    type: String,
    required: true
  },
  status: {
    type: String,
    default: ''
  },
  height: {
    type: String,
    default: `calc(100vh - 219px)`
  },
  groupName: {
    type: String,
    default: 'name'
  },
  dateTime: {
    type: String,
    default: ''
  }
})

const { t } = useI18n()
const route = useRoute()

const entityStore = useEntityStore()
const accountStore = useAccountStore()
const tableConfigStore = useReportsTableConfigStore()
const { processTableConfig } = storeToRefs(tableConfigStore)
const accountOverlayDrawerRef = useTemplateRef('accountOverlayDrawerRef')
const counterPartyOverlayDrawerRef = useTemplateRef('counterPartyOverlayDrawerRef')
const columnData = ref()
const firstLevelGroupDataList: any = ref([])
const secondLevelGroupDataList: any = ref([])
const gridApi = shallowRef<GridApi | null>(null)
const gridColumnApi = shallowRef<ColumnApi | null>(null)

const overlayDrawerData: any = reactive({
  type: 'accounts',
  currentData: {}
})

const loadingCellRendererParams = reactive({
  loadingMessage: 'Loading'
})
const groupLitsData: any = reactive({
  listData: {} // 分组后list的数据
})

const cacheBlockSize = computed(() => {
  if (accountStore.treasuryBalanceList.total.currencyList.length >= 100) {
    return 20
  }
  return 30
})

// ag-grid基础配置
const gridOptions: any = reactive({
  sidebar: false,
  headerHeight: 35,
  tooltipShowDelay: 500,
  // tooltipHideDelay: 5000,
  // columnHoverHighlight: true,
  rowModelType: 'serverSide',
  groupDisplayType: 'custom', // custom || groupRows
  suppressRowTransform: false,
  suppressContextMenu: true,
  animateRows: true,
  debounceVerticalScrollbar: false,
  suppressPropertyNamesCheck: true,
  suppressColumnVirtualization: false, // 开启列虚拟化
  suppressServerSideFullWidthLoadingRow: true,
  rowGroupPanelShow: 'never',
  domLayout: 'normal',
  // suppressserversideinfinitesscroll: true, // 设置服务器端行模型是否使用无限滚动
  maxBlocksInCache: 20, // 缓存中最多有多少块。如果达到最大值，块将从缓存中删除
  maxConcurrentDatasourceRequests: 1, // 有多少请求同时命中服务器。如果达到最大值，请求将排队
  cacheBlockSize: cacheBlockSize.value, // 缓存中每个块有多少行，即一次从服务器返回多少行
  blockLoadDebounceMillis: 200, // 加载块之前等待多少毫秒。在无限滚动和滚动许多无限块时很有用，因为它可以防止块加载直到滚动结束。
  serverSideInitialRowCount: 0, // 初始化从第几行开始显示
  defaultColDef: {
    resizable: true,
    suppressMovable: true,
    cellStyle: {
      height: '42px',
      'line-height': '42px' // 行高设置同步：跟rowHeight属性设置值一致
    },
    rowHeight: 42,
    tooltipComponent: CustomTooltip,
    tooltipValueGetter: (params: any) => {
      return params?.value
    }
  },
  overlayLoadingTemplate: '<span class="ag-overlay-loading-center">data is loading...</span>',
  overlayNoRowsTemplate: `<div class="elv-result">
    <img src="${noDataImg}" style="width: 40px; height: 40px;margin-bottom:16px" />
    <div class="elv-result-description">
      No data matches this filter
    </div>
  </div>`
})

const isShowAccountOverlayDrawer = computed(() => {
  return accountOverlayDrawerRef.value?.isShowViewDrawer
})

const entityId = computed(() => {
  return route.params?.entityId as string
})

const currentEntityPermission = computed(() => {
  return entityStore.entityPermission()
})

// 单元格点击事件
const onCellClicked = (cell: any) => {
  console.log(cell)
  columnData.value = cell
  overlayDrawerData.currentData = {}
  if (
    cell.node?.level !== 0 &&
    cell.value.cellName !== 'emptyTitle' &&
    cell.value.cellName !== 'Datasource provider' &&
    cell.event?.target?.className !== 'elv-financials-cell-address-main__copy' &&
    cell.node?.rowPinned !== 'bottom' &&
    cell.node?.rowPinned !== 'top'
  ) {
    overlayDrawerData.currentData = cell.data
    if (cell.data?.entityAccountId || cell.data?.entityAccount?.entityAccountId) {
      if (
        ['MEMBER', ''].includes(currentEntityPermission.value?.role) &&
        !currentEntityPermission.value?.entityAccount?.view
      ) {
        ElMessage.warning(t('message.noPermission'))
        return
      }
      accountOverlayDrawerRef.value?.onCheckDrawerStatus()
    }
  }
  overlayDrawerData.currentData.rowId = cell.node.id
}

// 分组数据处理
const groupRowDataFormatter = async () => {
  let data: any = {
    list: [],
    total: {}
  }
  const groupTwoData: any = []
  const accountFilter =
    accountStore.accountFilterList.find((item: any) => {
      return item.entityId === entityId.value
    }) ?? {}
  const accountParams = {
    date: props.dateTime,
    subGroup: 'NONE',
    sort: 'BALANCE',
    ...accountFilter?.data
  }
  if (!['NONE', 'PLATFORM'].includes(accountFilter.subGroup) && accountFilter?.data?.auxiliaryType?.type) {
    accountParams.subGroup = accountFilter?.data?.auxiliaryType?.type
    accountParams.auxiliaryTypeId = accountFilter?.data?.auxiliaryType?.auxiliaryTypeId
    delete accountParams.auxiliaryType
  }
  data = accountStore?.isInitTreasuryBalance
    ? await accountStore.fetchEntityRecentlyBalanceList(entityId.value, accountParams)
    : accountStore.treasuryBalanceList
  data.totalCount = data.list.length
  accountStore.isInitTreasuryBalance = false
  let rowData: any = []
  // 一级分组
  if (data?.list?.length > 0) {
    let groupIndex = 0
    data?.list.forEach((firstLevelGroupData: any, groupOneIndex: number) => {
      const tableFirstGroupData: any = []
      tableFirstGroupData.push({
        ...firstLevelGroupData,
        index: groupOneIndex,
        groupLevel: 1,
        label:
          firstLevelGroupData?.[props.groupName] !== 'CHAIN'
            ? capitalize(firstLevelGroupData?.[props.groupName])
            : 'On-Chain',
        [`firstLevelGroup`]:
          firstLevelGroupData?.[props.groupName] !== 'CHAIN'
            ? capitalize(firstLevelGroupData?.[props.groupName])
            : 'On-Chain'
      })
      rowData = [...rowData, ...tableFirstGroupData]
      const tableSecondGroupData: any = []
      // 二级分组
      if (firstLevelGroupData.list?.length > 0 && accountParams?.subGroup !== 'NONE') {
        firstLevelGroupData.list.forEach((secondLevelGroupData: any, groupTwoIndex: number) => {
          groupIndex += 1
          tableSecondGroupData.push({
            ...secondLevelGroupData,
            index: groupTwoIndex,
            groupThreeIndex: groupIndex - 1,
            groupLevel: 2,
            label:
              secondLevelGroupData?.platform?.slug ||
              secondLevelGroupData?.counterparty?.name ||
              secondLevelGroupData?.auxiliaryItem?.value,
            list: secondLevelGroupData?.list.filter((item: any) => item.entityAccount?.status === 'NORMAL'),
            [`secondLevelGroup`]: `${
              secondLevelGroupData?.platform?.slug ||
              secondLevelGroupData?.counterparty?.name ||
              secondLevelGroupData?.auxiliaryItem?.value
            }${groupOneIndex}${groupTwoIndex}`
          })
        })
      }
      groupTwoData.push(tableSecondGroupData)
    })
  }
  entityStore.loading = false
  firstLevelGroupDataList.value = rowData
  secondLevelGroupDataList.value = groupTwoData
  return {
    firstLevelGroupData: rowData,
    secondLevelGroupData: groupTwoData
  }
}

// 获取列表数据
const getTableGroupListData = async (params: any) => {
  const groupListParams: any = {
    page: 1,
    limit: cacheBlockSize.value
  }
  let tableGroupListData: any = []
  groupListParams.page =
    params.request.endRow / cacheBlockSize.value ? Number((params.request.endRow / cacheBlockSize.value).toFixed(0)) : 1
  if (isEmpty(accountStore.treasuryBalanceList?.list)) {
    const accountFilter =
      accountStore.accountFilterList.find((item: any) => {
        return item.entityId === entityId.value
      }) ?? {}
    const accountParams = {
      date: props.dateTime,
      subGroup: 'NONE',
      sort: 'BALANCE',
      ...accountFilter?.data
    }
    if (!['NONE', 'PLATFORM'].includes(params.subGroup) && accountFilter?.data?.auxiliaryType?.type) {
      accountParams.subGroup = accountFilter?.data?.auxiliaryType?.type
      accountParams.auxiliaryTypeId = accountFilter?.data?.auxiliaryType?.auxiliaryTypeId
      delete accountParams.auxiliaryType
    }
    if (accountStore?.isInitTreasuryBalance) {
      await accountStore.fetchEntityRecentlyBalanceList(entityId.value, accountParams)
    }
  }
  let list: any = []
  list =
    params?.parentNode?.field === 'secondLevelGroup'
      ? params?.parentNode?.data?.list
      : firstLevelGroupDataList.value?.[params?.parentNode?.data?.index]?.list

  tableGroupListData = list?.slice(params.request.startRow, params.request.endRow)
  return {
    list: tableGroupListData,
    totalCount: list?.length
  }
}

// 获取服务器列表数据
const getServerSideDataSource = () => {
  gridApi.value?.setServerSideDatasource({
    // eslint-disable-next-line consistent-return
    getRows: async (params: IServerSideGetRowsParams) => {
      try {
        loadingCellRendererParams.loadingMessage = 'Loading'
        if (isEmpty(params.request.groupKeys)) {
          if (accountStore?.isInitTreasuryBalance) {
            await groupRowDataFormatter()
          }
          // 一级分组框架
          gridOptions.maxConcurrentDatasourceRequests = 1
          gridApi.value?.hideOverlay()
          let lastRow: number = -1
          const tableGroupDataTotal: number = accountStore.treasuryBalanceList?.list?.length ?? 0
          if (tableGroupDataTotal < (params?.request?.endRow ?? 0)) {
            lastRow = Number(tableGroupDataTotal)
          }
          if (params.request.endRow === undefined || params.request.startRow === undefined) {
            lastRow = Number(tableGroupDataTotal)
          }
          if (Number(tableGroupDataTotal) === 0) {
            lastRow = 0
            gridApi.value?.showNoRowsOverlay()
          }
          if (props.status === 'success') {
            params.successCallback(firstLevelGroupDataList.value, lastRow)
          } else {
            loadingCellRendererParams.loadingMessage = 'ERR'
            params.fail()
          }
        } else {
          const accountFilter =
            accountStore.accountFilterList.find((item: any) => {
              return item.entityId === entityId.value
            }) ?? {}
          if (
            accountFilter.data?.subGroup !== 'NONE' &&
            accountFilter.data?.subGroup !== undefined &&
            params.parentNode.level === 0
          ) {
            // 分组为二级分组
            const groupRowData = await getTableGroupListData(params)
            let lastRow: number = -1
            const tableGroupDataTotal: number =
              firstLevelGroupDataList.value[params.parentNode.data?.index]?.list?.length ?? 0
            if (tableGroupDataTotal < (params?.request?.endRow ?? 0)) {
              lastRow = Number(tableGroupDataTotal)
            }
            if (params.request.endRow === undefined || params.request.startRow === undefined) {
              lastRow = Number(tableGroupDataTotal)
            }
            if (Number(tableGroupDataTotal) === 0) {
              lastRow = 0
              gridApi.value?.showNoRowsOverlay()
            }
            params.successCallback(groupRowData.list, lastRow)
          } else {
            // 一级分组下的list
            const tableGroupListData = await getTableGroupListData(params)
            params.success({
              rowData: tableGroupListData?.list ?? [],
              rowCount: tableGroupListData?.totalCount ?? 0
            })
          }
        }
      } catch (error) {
        console.log(error)
        loadingCellRendererParams.loadingMessage = 'ERR'
        if (route.name === 'entity-accounts-treasury') {
          params?.fail()
        }
      }
    }
  })
}

// ag-gridAPI初始化
const onGridReady = async (params: DetailGridInfo) => {
  // 获取gridApi
  gridApi.value = params?.api as GridApi
  gridColumnApi.value = params?.columnApi as ColumnApi
  accountStore.setAgGridApi(params?.api as GridApi, params?.columnApi as ColumnApi)
  // 获取服务器列表数据
  await groupRowDataFormatter()
  getServerSideDataSource()
}

// 刷新列表
const resetList = (isInit: boolean = true) => {
  accountStore.isInitTreasuryBalance = isInit
  firstLevelGroupDataList.value = []
  secondLevelGroupDataList.value = []
  gridApi.value?.ensureIndexVisible(0, 'top')
  gridApi.value?.refreshServerSide({ route: [], purge: true })
}

emitter.on('resetAccountList', resetList)

const onUpdateAccountOrContact = (data: any) => {
  overlayDrawerData.currentData = data
}

// 当前行唯一标识
const getRowId = (params: any) => {
  // eslint-disable-next-line no-nested-ternary
  const rowId = params.data?.label
    ? `${params.data?.label}${params.data?.index}`
    : // eslint-disable-next-line no-nested-ternary
      params?.parentKeys
      ? `${params?.parentKeys[0]}${params.data?.rowId}`
      : params.data?.rowId
  return uniqueId(rowId)
}

// 是否展开分组
const isServerSideGroupOpenByDefault = () => {
  return true
}

// 顶部行数据
const pinnedTopRowData = computed(() => {
  return [{ fieldValues: accountStore.treasuryBalanceList.total, defaultAggregation: '', name: '' }]
})

const updateAccountDetail = () => {
  accountOverlayDrawerRef.value?.updateAccountDetail()
}

defineExpose({
  groupLitsData,
  resetList,
  isShowAccountOverlayDrawer,
  updateAccountDetail
})

onBeforeMount(() => {
  gridOptions.cacheBlockSize = cacheBlockSize.value
})

onBeforeUnmount(() => {
  emitter.off('resetAccountList', resetList)
})
</script>

<style scoped lang="scss">
.elv-ag-theme-alpine {
  --ag-background-color: transparent;
  --ag-foreground-color: #0e1420;
  --ag-header-foreground-color: rgb(162, 174, 186);
  --ag-header-background-color: #f9fbfe;
  --ag-odd-row-background-color: transparent;
  // --ag-header-cell-hover-background-color: rgba(255, 255, 255 , 1)
  // --ag-odd-row-background-color: rgba(248, 250, 254, 0.5);
  --ag-font-size: 14px;
  // --ag-row-height: 42px;
  // --ag-line-height: 42px;
  --ag-list-item-height: 50px;
  --ag-header-column-resize-handle-height: 0%;
  --ag-header-column-resize-handle-width: 1px;
  --ag-grid-size: 5px;
  --ag-borders: 1px solid;
  --ag-border-color: #e4e7eb;
  --ag-row-border-color: #e4e7eb;
  // --ag-font-family: 'Barlow-Regular';
  --ag-borders-row: solid 1px;
  --ag-cell-horizontal-border: solid #e4e7eb;
  --ag-header-column-separator-display: block;
  height: calc(100% - 50px) !important;
}

:deep(.ag-root-wrapper) {
  border-color: #cedee0;
}

:deep(.ag-horizontal-left-spacer, .ag-horizontal-right-spacer) {
  overflow-x: overlay;
}

:deep(.ag-body-horizontal-scroll-viewport) {
  overflow-x: overlay;
}

:deep(.ag-header-group-cell-with-group) :deep(.ag-header-cell-label) {
  color: rgb(44, 46, 48);
  font-size: x-small;
  justify-content: right;
}

:deep(.ag-header) {
  height: 36px !important;
  min-height: 36px !important;
  border-bottom-color: #cedee0;
}

:deep(.ag-header-row):nth-child(1) {
  height: 36px !important;
  line-height: 34px;
  font-size: 11px;
  font-family: 'Plus Jakarta Sans';
  font-weight: 500;

  color: $elv-color-9BA8B5;
  //   letter-spacing: 0.075em;
  background-color: #eef4fb;
}

:deep(.ag-pinned-left-header),
:deep(.ag-pinned-left-cols-container) {
  box-shadow:
    6px 0px 12px rgba(0, 0, 0, 0.05),
    1px 0px 4px rgba(23, 83, 235, 0.1);
  z-index: 100;
}

:deep(.ag-floating-top) {
  .ag-pinned-left-floating-top,
  .ag-pinned-right-floating-top {
    .ag-row {
      border-bottom: 0px;
    }
  }

  .ag-pinned-left-floating-top {
    box-shadow:
      6px 0px 12px rgba(0, 0, 0, 0.05),
      1px 0px 4px rgba(23, 83, 235, 0.1);
  }

  .ag-pinned-right-floating-top {
    box-shadow:
      -6px 0px 12px rgba(0, 0, 0, 0.05),
      -1px 0px 4px rgba(23, 83, 235, 0.1);
  }
}

:deep(.ag-floating-top-viewport .ag-row) {
  border-bottom: 0px !important;
}

:deep(.ag-floating-bottom-viewport) {
  background: #f9fafb;
  height: 52px;

  .ag-row-pinned {
    height: 100% !important;
  }

  .ag-cell {
    height: 52px !important;
  }
}

.ag-floating-bottom .ag-row {
  background-color: #000;
}

:deep(.ag-header-cell) {
  color: $elv-color-E4E7EB;
  padding-left: 0;
  padding-right: 0;
}

:deep(.ag-overlay) {
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 1%, rgba(255, 255, 255, 1));
}

:deep(.ag-floating-bottom-full-width-container) {
  height: 52px;
}

:deep(.ag-floating-bottom) {
  height: 52px !important;
  min-height: 52px !important;
  border-top-color: #cedee0;

  .ag-cell {
    font-family: 'Barlow' !important;
    font-weight: 700 !important;
    font-size: 12px !important;
  }
}
// 隐藏工具面板
:deep(.ag-side-buttons) {
  display: none;
}

.elv-account-table {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background: url('@/assets/img/screener-watermark.png') no-repeat 58%;
  background-size: 631px 200px;
  margin-left: -1px;
}

:deep(.ag-row) {
  .ag-cell {
    border-left-width: 0px;
    border-top-width: 0px;
    border-bottom-width: 0px;
    display: flex;
    align-items: center;
    padding: 0 10px;
  }
}

:deep(.ag-header-group-cell-label) {
  justify-content: center;
}

:deep(.el-button.is-text:not(.is-disabled):active) {
  background-color: $elv-color-F9FBFE;
}

:deep(.ag-theme-alpine .ag-tabs-header) {
  display: none;
}

:deep(.ag-theme-alpine .ag-tabs-body) {
  width: 150px;
  box-sizing: border-box;
}

:deep(.ag-tabs) {
  min-width: auto;
}

:deep(.ag-row-group) {
  height: 42px;
  line-height: 42px;

  .ag-cell-value {
    height: 42px !important;
    line-height: 42px !important ;
  }

  .ag-cell {
    font-family: 'Barlow' !important;
    font-weight: 700 !important;
  }
}

:deep(.ag-row-level-0) {
  .ag-cell-value {
    &:has(.elv-table-group-rows) {
      padding-left: 11px;
    }

    &:has(.elv-financials-total) {
      height: 80px !important;
    }

    &.ag-cell-first-right-pinned {
      &:has(.elv-financials-cell-event-main.is-pinned):hover {
        background: #edf0f3;
      }
    }
  }

  &:hover {
    background: #f9fafb;

    .elv-ag-index-select {
      display: flex;
    }
  }
}

:deep(.ag-row-level-0.ag-row-group) {
  background: #fbf9f7;
}

:deep(.ag-row-level-1.ag-row-group) {
  background: #fafcff;
}

:deep(.ag-row-level-2.ag-row-group) {
  background: $elv-color-F5FEF4;
}

:deep(.ag-row-level-1) {
  .elv-table-group-rows-content__label {
    background: $elv-color-DCE6FF;
    border: 1px solid $elv-color-C4D6FF;
  }

  .ag-cell-value {
    &:has(.elv-financials-total) {
      height: 80px !important;
    }

    &:has(.elv-table-group-rows) {
      padding-right: 0px;
    }
  }
}

:deep(.ag-row-level-2) {
  .elv-table-group-rows-content__label {
    background: $elv-color-D7F7D2;
    border: 1px solid $elv-color-CCEAC7;
  }

  .ag-cell-value {
    &:has(.elv-ag-group-index) {
      border-right: 0px;
    }

    &:has(.elv-table-group-rows) {
      padding-right: 0px;
    }
  }
}

:deep(.ag-row-level-3) {
  .elv-ag-index {
    display: none;
  }

  .ag-cell-value {
    &:has(.elv-ag-index) {
      border-right: 0px;
    }

    &:has(.elv-table-group-rows__index) {
      padding-left: 38px;
    }
  }
}

:deep(.ag-row-group-contracted) {
  &:first-of-type {
    .ag-cell-value {
      padding-top: 0px;
    }
  }

  .ag-cell {
    border-width: 0px;
    border-right-width: 1px;
  }

  .elv-ag-group-arrow {
    height: 42px;
  }
}

:deep(.ag-pinned-right-floating-bottom) {
  box-shadow:
    6px 0px 12px rgba(0, 0, 0, 0.05),
    1px 0px 4px rgba(23, 83, 235, 0.1);

  .ag-row {
    background-color: #f9fafb;
    height: 52px !important;
  }

  .ag-cell-value {
    height: 52px !important;

    &:nth-child(1) {
      border-right: 0px;
    }
  }
}

:deep(.ag-pinned-right-header) {
  box-shadow:
    -6px 0px 12px rgba(0, 0, 0, 0.05),
    -1px 0px 4px rgba(23, 83, 235, 0.1);
}

:deep(.ag-pinned-right-cols-container) {
  position: relative;

  &::before {
    position: absolute;
    width: 4px;
    height: 100%;
    content: '';
    left: -4px;
    top: 0px;
    background: linear-gradient(270deg, rgba(0, 0, 0, 0.05) 0%, rgba(0, 0, 0, 0) 100%);
  }
}

:deep(.ag-row-group-expanded) {
  .ag-cell {
    border-left-width: 0px;
    border-top-width: 0px;
  }
}

:deep(.ag-ltr .ag-cell-focus:not(.ag-cell-range-selected):focus-within) {
  border: none;
  border-right: 1px solid $elv-color-E4E7EB !important;
}

:deep(.ag-header-cell-comp-wrapper) {
  height: 34px;
}

:deep(
    .ag-pinned-left-header
      .ag-header-row-column-group
      .ag-header-group-cell-with-group:nth-child(2)
      .ag-header-group-cell-label
  ) {
  font-family: 'Plus Jakarta Sans';
  font-weight: 500;
  font-size: 11px;
  transform: scale(0.91);
  line-height: 14px;
  color: $elv-color-9BA8B5;
  justify-content: flex-start !important;
  text-transform: lowercase;
}
</style>
