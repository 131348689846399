<template>
  <el-dialog
    v-model="showAccountBalanceDialog"
    width="30%"
    align-center
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    class="elv-account-balance-dialog"
    @close="onCloseDialog"
  >
    <template #header="{ titleId }">
      <h4 :id="titleId" class="elv-account-balance-dialog-header__title">
        {{ t('button.addBalance') }}
      </h4>
    </template>
    <div class="elv-account-balance-dialog-info">
      <p>{{ props.currentData?.name }}</p>
      <div>
        <img :src="props.currentData?.underlyingCurrency?.logo" alt="" />{{
          props.currentData?.underlyingCurrency?.showSymbol
        }}
      </div>
    </div>
    <el-form ref="balanceFormRef" :model="balanceForm" label-position="left" :rules="rules">
      <el-form-item :label="t('common.dateTime')" prop="datetimeStr">
        <el-date-picker
          ref="datePickerStartPopoverRef"
          v-model="balanceForm.datetimeStr"
          popper-class="elv-account-balances-date-popper"
          type="datetime"
          :placeholder="t('common.select')"
          value-format="YYYY-MM-DD HH:mm:ss"
          format="YYYY/MM/DD HH:mm:ss"
          :clearable="false"
          :prefix-icon="DatetimeIcon"
        />
      </el-form-item>
      <el-form-item :label="t('common.timezone')" prop="timezone">
        <el-select
          v-model="balanceForm.timezone"
          filterable
          default-first-option
          :placeholder="t('common.select')"
          :suffix-icon="SelectSuffixIcon"
        >
          <el-option
            v-for="(item, index) in timezoneList"
            :key="index"
            :label="`${item.area} (${item.timezone})`"
            :value="item.area"
          />
        </el-select>
      </el-form-item>
      <el-form-item :label="t('common.balance')" prop="balance">
        <el-input-number v-model="balanceForm.balance" :placeholder="t('message.pleaseInput')"
      /></el-form-item>
    </el-form>

    <template #footer>
      <elv-button
        type="primary"
        :disabled="disabledSave"
        :loading="saveLoading"
        round
        width="100"
        height="44"
        @click="onSaveBalance"
        >{{ t('button.save') }}</elv-button
      >
    </template>
  </el-dialog>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { ElMessage } from 'element-plus'
import AccountsApi from '@/api/AccountsApi'
import timezoneList from '@/config/timezone'
import DatetimeIcon from './DatetimeIcon.vue'
import { useEntityStore } from '@/stores/modules/entity'
import { useAccountStore } from '@/stores/modules/accounts'
import type { FormInstance, FormRules } from 'element-plus'
import SelectSuffixIcon from '@/components/Project/SelectSuffixIcon.vue'

const props = defineProps({
  currentData: {
    type: Object,
    default: () => {
      return {}
    }
  }
})

const { t } = useI18n()
const route = useRoute()
// eslint-disable-next-line no-unused-vars
const accountStore = useAccountStore()
const entityStore = useEntityStore()

const balanceFormRef = useTemplateRef<FormInstance>('balanceFormRef')
const saveLoading = ref(false)
const showAccountBalanceDialog = ref(false)
const balanceForm = reactive({
  entityAccountId: '',
  currency: '',
  datetimeStr: '',
  timezone: '',
  balance: null
})

const rules = reactive<FormRules>({
  datetimeStr: [
    {
      required: true,
      trigger: 'blur'
    }
  ],
  timezone: [
    {
      required: true,
      trigger: 'blur'
    }
  ],
  balance: [
    {
      required: true,
      trigger: 'blur'
    }
  ]
})

const emit = defineEmits(['resetDetail'])

const entityId = computed(() => {
  return String(route.params?.entityId as string)
})

const currentEntityPermission = computed(() => {
  return entityStore.entityPermission()
})

const disabledSave = computed(() => {
  return !balanceForm.datetimeStr || !balanceForm.timezone || balanceForm.balance === null
})
// 切换删除弹窗展示
const onCheckAccountBalanceDialog = () => {
  showAccountBalanceDialog.value = !showAccountBalanceDialog.value
}

const onCloseDialog = () => {
  balanceFormRef.value?.resetFields()
}

const resetList = () => {
  onCheckAccountBalanceDialog()
  emit('resetDetail')
}

const onSaveBalance = async () => {
  if (
    ['MEMBER', ''].includes(currentEntityPermission.value?.role) &&
    !currentEntityPermission.value?.entityAccount?.balanceAdd
  ) {
    ElMessage.warning(t('message.noPermission'))
    return
  }
  if (!balanceFormRef.value) return
  await balanceFormRef.value.validate(async (valid: boolean) => {
    if (valid) {
      try {
        saveLoading.value = true
        const params = {
          ...balanceForm,
          entityAccountId: props.currentData?.entityAccountId,
          currency: props.currentData?.currency
        }
        await AccountsApi.createTreasuryBalanceCheckpoint(entityId.value, params)
        ElMessage.success(t('message.saveSuccess'))
        resetList()
      } catch (error: any) {
        console.log(error)
        ElMessage.error(error.message)
      } finally {
        saveLoading.value = false
      }
    }
  })
}

defineExpose({ onCheckAccountBalanceDialog, showAccountBalanceDialog })

watch(
  () => showAccountBalanceDialog.value,
  async () => {
    if (showAccountBalanceDialog.value) {
      console.log(234324)
    }
  }
)
</script>

<style lang="scss">
.elv-account-balance-dialog {
  width: 490px;
  min-height: 382px;
  box-shadow:
    0px 2px 6px rgba(0, 0, 0, 0.05),
    0px 0px 1px rgba(0, 0, 0, 0.3);
  border-radius: 6px;

  .el-dialog__header {
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    height: 54px;
    font-family: 'Plus Jakarta Sans';
    font-weight: 700;
    margin: 0;
    padding: 0;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: flex-start;
    color: #0e0f11;
    padding-left: 24px;
    border-bottom: 1px solid #edf0f3;

    .elv-account-balance-dialog-header__title {
      font-family: 'Plus Jakarta Sans';
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      color: #0e0f11;
      margin: 0;
    }
  }

  .el-dialog__body {
    padding: 18px 24px 0px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    p {
      margin: 0;
    }

    .elv-account-balance-dialog-info {
      display: flex;
      padding-top: 8px;
      padding-bottom: 24px;
      justify-content: space-between;
      align-items: center;
      align-self: stretch;
      color: #1e2024;
      border-bottom: 1px solid #edf0f3;
      margin-bottom: 16px;

      p {
        font-family: 'Plus Jakarta Sans';
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }

      div {
        display: flex;
        align-items: center;
        font-family: 'Barlow';
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;

        img {
          width: 24px;
          height: 24px;
          display: block;
          border-radius: 50%;
          margin-right: 8px;
        }
      }
    }

    .el-input-number {
      width: 190px;
      height: 44px;
      border-radius: 4px;

      .el-input-number__decrease,
      .el-input-number__increase {
        display: none;
      }

      .el-input__inner {
        text-align: right;
      }
    }

    .el-input {
      width: 190px;
      height: 44px;
      border-radius: 4px;

      &.is-disabled {
        background: #f9fafb;
      }

      &.el-date-editor {
        .el-input__wrapper {
          flex-direction: row-reverse;

          .el-input__prefix-inner {
            .el-icon.el-input__icon {
              margin-right: 0px;
            }

            svg {
              width: 16px;
              height: 16px;
              fill: #636b75;
            }
          }
        }
      }
    }

    .el-input__inner {
      font-family: 'Barlow';
      font-weight: 500;
      font-size: 14px;
      color: #0e0f11;
    }

    .el-input__wrapper {
      border-radius: 4px;
      border: 1px solid #dde1e6;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
      padding: 1px 12px;
      transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    }

    .el-input:not(.is-disabled) .el-input__wrapper {
      &:hover {
        border: 1px solid #7596eb !important;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
      }

      &.is_focus {
        border: 1px solid #7596eb !important;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
      }
    }

    .el-form {
      width: 100%;
    }

    .el-form-item {
      margin-bottom: 8px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &.elv-account-balances-form-item-date {
        .el-form-item__content {
          margin-left: 8px;
        }
      }

      .el-form-item__content {
        width: 190px;
        flex: none;
      }

      &:last-of-type {
        margin-bottom: 0px;
      }

      &.is-error {
        .el-input__wrapper {
          background: #faeee6;
          border: 1px solid #7e4a15;
        }

        .el-input__inner {
          color: #7e4a15;
        }

        .el-form-item__error {
          font-family: 'Plus Jakarta Sans';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          color: #7e4a15;
        }
      }

      .el-form-item__label {
        font-family: 'Plus Jakarta Sans';
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
        color: #636b75;
        align-items: center;

        &::before {
          display: none;
        }
      }
    }

    .el-select {
      &:hover:not(.el-select--disabled) .el-input__wrapper,
      .el-input.is-focus:not(.el-select--disabled) .el-input__wrapper {
        border: 1px solid #7596eb !important;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
      }
    }

    .el-input__wrapper.is-focus {
      border: 1px solid #7596eb !important;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
    }
  }

  .el-dialog__footer {
    padding: 20px 0px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.elv-account-balances-date-popper {
  &.el-popper {
    transform: translateY(-11.5px);
    border: 1px solid #e4e7eb;
    box-shadow:
      0px 8px 16px rgba(0, 0, 0, 0.04),
      0px 4px 8px rgba(0, 0, 0, 0.04),
      0px 0px 1px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    // overflow: hidden;
  }

  .el-picker-panel__body-wrapper {
    display: flex;
    flex-direction: column-reverse;
  }

  .el-date-range-picker__content.is-left {
    border-right: 0px;
  }

  .el-date-range-picker .el-picker-panel__content {
    padding: 8px;
  }

  .el-date-picker {
    width: 237px;

    .el-picker-panel__content {
      width: 221px;
      margin: 0;
      margin-top: 6px;

      .el-date-table {
        th {
          min-width: 31.57px;
          height: 16px;
          border-bottom: 0px;
          padding: 0px;
          font-family: 'Barlow';
          font-weight: 500;
          font-size: 11px;
          line-height: 11px;
          color: #aaafb6;
        }

        tr {
          color: #636b75;
        }

        td {
          padding: 0px;
          width: 31.57px;
          height: 27.8px;
          border-radius: 2px;

          &.today .el-date-table-cell__text {
            color: #1e2024;
          }

          &.available:hover {
            border-radius: 2px;
            background: #edf0f3;

            .el-date-table-cell__text {
              color: #1e2024;
              font-weight: 600;
            }
          }

          &.next-month {
            display: none;

            &:hover {
              border-radius: 2px;
              background: #edf0f3;

              .el-date-table-cell__text {
                color: #1e2024;
                font-weight: 600;
              }
            }
          }

          &.prev-month {
            visibility: hidden;

            &:hover {
              border-radius: 2px;
              background: #edf0f3;

              .el-date-table-cell__text {
                color: #1e2024;
                font-weight: 600;
              }
            }
          }

          .el-date-table-cell {
            padding: 0px;
            width: 31.57px;
            height: 27.8px;

            .el-date-table-cell__text {
              width: 31.57px;
              height: 27.8px;
              font-family: 'Barlow';
              font-weight: 500;
              font-size: 13px;
              line-height: 13px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 0px;
            }
          }

          &.in-range .el-date-table-cell {
            background-color: #fff;
          }

          &.current .el-date-table-cell {
            margin-left: 0px;
            border-radius: 0px;

            .el-date-table-cell__text {
              color: #0e0f11;
              font-weight: 600;
              background-color: #fff;
              box-sizing: border-box;
              border-bottom: 1px solid #1343bf;
            }
          }
        }
      }
    }

    .el-picker-panel__body {
      padding: 8px;

      .el-date-picker__time-header {
        padding: 0px;
        padding-bottom: 8px;

        .el-input {
          height: 28px;

          .el-input__inner {
            color: #0e0f11;
            font-family: 'Barlow';
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 28px;
          }
        }
      }

      .el-time-panel {
        left: -75px;
      }
    }

    .el-date-picker__header {
      margin: 0;
      padding: 0;
    }

    .el-date-picker__header-label {
      font-family: 'Plus Jakarta Sans';
      font-weight: 500;
      font-size: 13px;
      line-height: 14px;
      color: #aaafb6;
      padding: 0;
      position: relative;
      top: 4px;

      &:nth-child(3) {
        font-weight: 700;
        color: #1e2024;
        margin-left: 4px;
      }
    }
  }

  .el-date-range-picker {
    &.has-time {
      width: 392px;
      min-height: 245px;
      max-height: 273px;
      overflow: hidden;
      background: #ffffff;
    }

    .el-picker-panel__body {
      min-width: 392px;
    }
  }

  .el-picker-panel__icon-btn {
    margin-top: 10.5px;
  }

  .el-date-range-picker__header,
  .el-date-picker__header {
    height: 34px;
    border-bottom: 1px solid #edf0f3;

    div {
      font-family: 'Plus Jakarta Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 13px;
      line-height: 34px;
      color: #1e2024;
      margin: 0px;
    }
  }

  .el-picker-panel__sidebar {
    display: flex;
    border: 0px;
    bottom: -30px;
    left: 8px;
    padding-top: 0;
    z-index: 1;
    height: 21px;
    width: auto;
    top: auto;

    .el-picker-panel__shortcut {
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      padding: 4px 10px;
      min-width: 65px;
      max-width: 73px;
      width: auto;
      height: 21px;
      border: 1px solid #d0d4d9;
      border-radius: 11px;
      font-family: 'Plus Jakarta Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 13px;
      color: #636b75;
      margin-right: 4px;

      &:hover {
        background: #e6edff;
        border: 1px solid #5e85eb;
        color: #1753eb;
        font-weight: 600;
      }
    }
  }

  .el-picker-panel__body {
    margin-left: 0;
    height: auto;

    .el-date-range-picker__time-header {
      display: none;
    }
  }

  .el-date-table {
    th {
      min-width: 25.71px;
      height: 16px;
      border-bottom: 0px;
      padding: 0px;
      font-family: 'Barlow';
      font-weight: 500;
      font-size: 11px;
      line-height: 11px;
      color: #aaafb6;
    }

    tr {
      color: #636b75;
    }

    td {
      padding: 0px;
      width: 25.71px;
      height: 27.8px;
      border-radius: 2px;

      &.today .el-date-table-cell__text {
        color: #1e2024;
      }

      &.available:hover {
        border-radius: 2px;
        background: #edf0f3;

        .el-date-table-cell__text {
          color: #1e2024;
          font-weight: 600;
        }
      }

      &.next-month {
        display: none;

        &:hover {
          border-radius: 2px;
          background: #edf0f3;

          .el-date-table-cell__text {
            color: #1e2024;
            font-weight: 600;
          }
        }
      }

      &.prev-month {
        visibility: hidden;

        &:hover {
          border-radius: 2px;
          background: #edf0f3;

          .el-date-table-cell__text {
            color: #1e2024;
            font-weight: 600;
          }
        }
      }

      .el-date-table-cell {
        padding: 0px;
        width: 25.71px;
        height: 27.8px;

        .el-date-table-cell__text {
          width: 25.71px;
          height: 27.8px;
          font-family: 'Barlow';
          font-weight: 500;
          font-size: 13px;
          line-height: 13px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 0px;
        }
      }

      &.in-range .el-date-table-cell {
        background-color: #fff;
      }

      &.start-date .el-date-table-cell {
        margin-left: 0px;
        border-radius: 0px;

        .el-date-table-cell__text {
          color: #0e0f11;
          font-weight: 600;
          background-color: #fff;
          box-sizing: border-box;
          border-bottom: 1px solid #1343bf;
        }
      }

      &.end-date .el-date-table-cell {
        margin-right: 0px;
        border-radius: 0px;

        .el-date-table-cell__text {
          border-right: 0px;
          color: #0e0f11;
          font-weight: 600;
          background-color: #fff;
          box-sizing: border-box;
          border-bottom: 1px solid #1343bf;
        }
      }
    }
  }

  .el-picker-panel__footer {
    padding: 0px;
    margin: 0 8px;
    padding-top: 8px;
    box-sizing: border-box;
    height: 37px;

    .el-picker-panel__link-btn {
      height: 21px;
      margin-top: -7px;
    }
  }
}

.elv-account-balances-dialog-popper {
  &.el-popper {
    transform: translateY(-11.5px);
  }

  .el-select-dropdown__list {
    padding: 0;
  }

  .el-select-dropdown__item {
    height: 40px;
    display: flex;
    align-items: center;
    padding-left: 12px;
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #0e0f11;

    &.hover {
      background: #f9fafb;
    }

    .elv-account-balances-dialog-exchange-option {
      display: flex;
      align-items: center;

      img {
        display: block;
        width: 20px;
        height: 20px;
        filter: drop-shadow(0px 1px 3px rgba(33, 27, 78, 0.15));
        margin-right: 10px;
      }

      p {
        font-family: 'Plus Jakarta Sans';
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: #0e0f11;
        display: flex;
        align-items: center;
      }

      span {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2px 4px;
        height: 18px;
        border: 1px solid #dde1e6;
        border-radius: 3px;
        margin-left: 10px;
        font-weight: 500;
        font-size: 11px;
        line-height: 14px;
        color: #d0d4d9;
      }
    }
  }
}
</style>
